.areaContainer {
  @apply overflow-hidden border border-solid rounded-md border-gray bg-code;
}

.area {
  @apply w-full p-4 text-sm font-bold text-white border-none outline-none bg-code;
}

.validator {
  @apply h-6 px-2 py-1 overflow-hidden text-sm bg-gray whitespace-nowrap text-ellipsis;
}

.pretty {
  @apply overflow-hidden;
}

.button {
  @apply z-100 absolute top-1 right-4;
}
