.pagination {
  @apply bg-white p-3 flex items-center justify-between text-gray-light border-t border-disabled border-solid border-l-0 border-r-0 border-b-0;
}

@media (max-width: 350px) {
  .detailedRange {
    @apply hidden;
  }
}

.pageButtons {
  @apply flex items-center;

  & button {
    @apply cursor-pointer flex items-center justify-center;
  }

  .pageIndicator {
    @apply mx-6 flex space-x-6 mobile:mx-2 mobile:space-x-2;
  }

  .pageButton {
    @apply text-base font-bold cursor-pointer;

    &.active {
      @apply text-primary-lighter;
    }
  }
}

.toolbarPanel {
  @apply p-3 w-full h-full bg-white;

  .actionButtons {
    @apply flex flex-col space-y-2 justify-center items-center mt-2;

    button {
      @apply w-full;
    }
  }

  .columns {
    @apply mt-4;
  }
}

:global(.ag-checkbox) :global(.ag-wrapper.ag-checkbox-input-wrapper) {
  @apply relative rounded-sm border border-solid border-muted bg-white outline-none;
  &::after {
    content: "";
  }

  &:global(.ag-indeterminate) {
    @apply border-primary-darker;
    &::after {
      @apply top-0.5 left-0.5 bottom-0.5 right-0.5 bg-primary-darker rounded-sm;
    }
  }

  &:global(.ag-checked) {
    @apply bg-primary-darker border-primary-darker;
    &::after {
      background-image: url("../../static/icons/gridCheckboxMark.svg");
      @apply top-0 left-0 bottom-0 right-0 bg-contain;
    }
  }

  input {
    @apply absolute cursor-pointer -top-3.5 -left-4 h-10 w-11;
  }
}

:global(.ag-invisible) {
  display: none !important;
}

// FIXME: Hope this doesn't lead to issues...
:global(.ag-cell-wrapper) {
  @apply h-full;
}
