@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-400.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-700.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-900.woff2') format('woff2');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

@import './variables.css';
@import '@sandstreamdev/react-swipeable-list/dist/styles.css';

@media print {
  @page {
    size: landscape;
  }
}

html {
  margin: 0;
  padding: 0;
}
:root {
  --sab: env(safe-area-inset-bottom);
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

/* Make scrollbar in Chrome/Edge nicer */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f3f5f7; /* color of the track */
}

.scrollbar-track-white::-webkit-scrollbar-track{
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #c1c7cd; /* color of the scroll thumb */
  border-radius: 6px; /* roundness of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #697077; /* color of the scroll thumb on hover */
}

html, body {
  font-family: 'Lato', sans-serif;
  height: 100%;
  width: 100%;
}

#app, #root {
  min-height: 100%;
  display: flex;
  flex-flow: column;
  font-size: 0.938rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  @apply cursor-pointer;
  border: none;
  background: none;
}

/*override ant styles*/
input, button, select, optgroup, textarea,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title,
.ant-tabs-nav-container,
.ant-calendar-picker,
.ant-table,
.ant-checkbox-wrapper,
.ant-pagination,
.ant-btn,
.ant-tabs,
.ant-input,
.ant-form-item,
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-form label,
.ant-time-picker-input,
.ant-time-picker-panel-select,
.ant-progress-line,
input.ant-calendar-picker-input {
  @apply text-base;
}

.recharts-text {
  @apply text-muted font-medium text-base leading-6;
}


.ant-progress-line {
  line-height: 1rem;
}



.ant-input:focus {
  border-color: var(--primary-6);
  box-shadow: none;
}

.ant-input-number {
  width: 100%;
}

.ant-input-suffix {
  color: #878d96 !important;
}

/* Fix padding left for input with prefix */
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 2.5rem;
}

input::placeholder, .ant-select-selection__placeholder {
  color: #878d96 !important;
}

.ant-select.changedValue .ant-select-selection, .ant-dropdown-trigger.changedValue, .ant-input-number.changedValue, input.changedValue, a.changedValue {
  @apply border-feedback-success;
}

.ant-calendar-picker.changedValue {
  input.ant-calendar-picker-input {
    @apply border-feedback-success;
  }
}

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  box-shadow: none;
}

.ant-popover {
  z-index: 1200 !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-switch-checked {
  background-color: var(--primary-6);
}

.ant-switch-small {
  min-width: 32px;
  height: 16px;
  line-height: 14px;

  &:after {
    width: 10px;
    height: 10px;
    top: 2px;
    left: 2px;
  }

  &.ant-switch-checked:after {
    left: 100%;
    margin-left: -2px;
  }

  &.ant-switch-checked .ant-switch-inner {
    margin-right: 27px;
  }

  & .ant-switch-inner {
    margin-left: 27px;
  }

  &:not(.ant-switch-disabled):active {
    &:before,
    &:after {
      width: 16px;
    }
  }
}

.ant-spin-container, .ant-spin-nested-loading {
  @apply flex flex-col flex-grow flex-shrink;
}

.ant-dropdown-menu {
  padding: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.ant-dropdown-menu-item {
  padding: 8px 12px;

  > a {
    color: #878d96;
    line-height: 24px;
  }
}

.ant-calendar-date-panel {
  user-select: none;
}

.ant-layout {
  @media print {
    background: var(--white);
  }
}

.ant-btn {
  letter-spacing: 0.1px;
}

.ant-btn:hover,
.ant-btn:focus {
  color: var(--link-hover);
  background: #c1e3ff;
  border-color: #c1e3ff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffffff;
  background: #113e6f;
  border-color: #113e6f;
}

.ant-btn-danger {
  color: #ffffff;
  background: #da1e28;
  border: #da1e28;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fed7d4;
  background: #a1020a;
  border-color: #a1020a;
}

.ant-btn-link {
  border: none;
  background: none;
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: var(--primary-6);
  background: none;
  border: none;
}

.ant-tabs {
  overflow: visible;
}

.ant-tabs-bar {
  @apply mb-6;
}

.ant-tabs-tab {
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #878d96;
  padding: 0 0 8px 0 !important;
  font-weight: 700;

  &:hover {
    color: var(--primary-6) !important;
  }
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: var(--primary-6);
  font-weight: 700;
}

.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: #878d96 !important;
}

.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  padding-bottom: 8px;
}

.ant-tabs-ink-bar {
  background-color: var(--primary-6);
}

.ant-tabs-top-bar {
  border-width: 2px;
  border-color: #dde1e6;
}

.ant-tabs-nav-container {
  margin-bottom: -2px;
}

.ant-radio {
  border-color: var(--primary-9);
}

.ant-radio-inner {
  background-color: var(--white);
  border-color: #a2a9b0;

  &:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    background-color: var(--primary-9);
    border-radius: 10px;
  }
}

.ant-message-notice {
  @apply p-0 mt-0;
}

.ant-message-notice-content {
  @apply inline-flex justify-center p-0 bg-none;
  box-shadow: none;

  svg {
    @apply mr-2;
  }
}

.ant-message-success {
  background-color: #daf8d6;
  color: #3aa166;

  svg {
    fill: #3aa266;
  }
}

.ant-message-warning {
  background-color: #ffefb7;
  color: #f4c10c;

  svg {
    fill: #f4c10c;
  }
}

.ant-message-error {
  background-color: #fed7d4;
  color: #da1e28;

  svg {
    fill: #da1e28;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 600px;
  min-height: 56px;
  border-radius: 0px 0px 8px 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@media (--tablet-down) {
  .ant-message-notice {
    display: flex;
    width: 100%;
  }

  .ant-message-notice-content {
    padding: 0.75rem;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .ant-message-custom-content {
    padding: 0.5rem;
    min-width: 0;
    width: 100%;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16) !important;
  }
}

.react-resizable-handle {
  width: 24px;
  height: 24px;
  padding: 2px;
  background: none;
}

.react-grid-layout {
  margin: -12px 0 0 -12px;
}

.react-grid-item.react-grid-placeholder {
  background: #dde1e6;
  opacity: 1;
  border-radius: 8px;
}
.react-grid-item.resizing {
  z-index: 3;
}

.recharts-default-tooltip {
  border-radius: 8px;
  padding: 12px !important;
  border: none !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
}
.recharts-tooltip-label {
  font-size: 12px;
  color: #878d96;
  font-weight: 700;
}
.recharts-tooltip-item {
  font-size: 12px;
  color: #878d96;
}
.recharts-legend-item-text {
  font-size: 12px;
  color: #878d96;
}
.recharts-legend-item > svg {
  width: 16px;
  height: 16px;
}

/* Some fixes for when tailwind is destroying ant-design */
.ant-tabs-top-bar {
  border-width: 0 0 2px 0;
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 0;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

.badge-green,
.badge-yellow {
  @apply relative;
  input {
    @apply pl-8;
  }
  &::before {
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    bottom: 14px;
    left: 0.5rem;
  }
}

.badge-green::before {
  background-color: #6df1a5;
}

.badge-yellow::before {
  background-color: #fde17d;
}

.mono-font {
  font-family: "JetBrains Mono", "Cascadia Code", ui-monospace, monospace !important;
}

.actions-column {
  @apply text-center flex justify-center items-center space-x-1;
  min-width: 6rem !important;
  max-width: 6rem !important;
}

.actions-column.wide {
  min-width: 8rem !important;
  max-width: 8rem !important;
}

.actions-column-wide {
  @apply text-center;
  min-width: 8rem !important;
  max-width: 8rem !important;
}

.ant-empty, .ant-pagination-item {
  @apply text-base;
}

@tailwind components;
@tailwind utilities;
@import './AntCalendar.scss';
@import './AntSelect.scss';

@import 'react-grid-layout/css/styles.css';
@import 'react-resizable/css/styles.css';

.react-grid-item {
  @apply z-10;
}

/* HACK: fix webpack compiled problems not being closable */
#webpack-dev-server-client-overlay {
  pointer-events: auto;
}
