.xxxl {
  // 96 px / 6 rem
  @apply w-24 h-24;
}

.xxl {
  // 64 px / 4 rem
  @apply w-16 h-16;
}

.xl {
  // 40 px / 2,5 rem
  @apply w-10 h-10;
}

.l {
  // 32 px / 2 rem
  @apply w-8 h-8;
}

.m {
  // 24 px / 1,5 rem
  @apply w-6 h-6;
}

.s {
  // 20 px / 1,25 rem
  @apply w-5 h-5;
}

.xs {
  // 16 px / 1 rem
  @apply w-4 h-4;
}

.xxs {
  // 12 px / 0,75 rem
  @apply w-3 h-3;
}
