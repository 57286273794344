.root {
  @apply w-11 h-6 rounded-full relative cursor-pointer bg-muted p-0;
}

.root[data-state="checked"] {
  @apply bg-primary-lighter;

  & .child {
    @apply left-0 ml-1 mr-0 right-auto;
  }
}

.thumb {
  @apply block w-4 h-4 bg-white rounded-full shadow will-change-transform transition-transform;
  transform: translateX(0.25rem);
}

.label {
  @apply text-base select-none pr-2 leading-none;
}

.child {
  @apply absolute top-0 left-auto right-0 mr-1 text-white;
}

.thumb[data-state="checked"] {
  transform: translateX(1.5rem);
}

.small {
  @apply w-8 h-4;

  & .thumb {
    @apply w-3 h-3;
  }

  & .thumb[data-state="checked"] {
    transform: translateX(1rem);
  }
}